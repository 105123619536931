import React from 'react';
import { makeStyles } from '@mui/styles';
import loadingGif from '../../assets/gif/loader.gif'; // Import your GIF file here

const useStyles = makeStyles((theme) => ({
    loadingspinnerDiv: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.65)', /* Set the opacity you want for the background */
        zIndex: '999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loaderGif: {
        height: '300px',
        width: '300px',
        marginBottom: '100px'
    }
}));

function Loader() {
    const classes = useStyles();
    return (
        <div className={classes.loadingspinnerDiv}>
            <img className={classes.loaderGif} src={loadingGif} alt="Loading" />
        </div>
    );
}

export default Loader;

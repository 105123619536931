import { createSlice } from "@reduxjs/toolkit";

// Move the logic to the store for fixing the issue of hardcoded ids in AddProKioskPopUp and EditPlanOption. This is a quick fix
const setKioskListByPlan = (state, action) => {

    const plans = action.payload;
    const kioskListByPlan = plans?.reduce((acc, plan) => {
        const isStandingKioskIncluded = plan?.options?.find(option => option.is_kiosk_included &&  option.key_name === "ssk_standing_included");
        const isCounterKioskIncluded = plan?.options?.find(option => option.is_kiosk_included &&  option.key_name === "ssk_countertop_included");
        const isClampKioskIncluded = plan?.options?.find(option => option.is_kiosk_included &&  option.key_name === "ssk_clamp_included");
        const planKioskList = [];
        if(isStandingKioskIncluded) {
            planKioskList.push({
                id: isStandingKioskIncluded.id,
                name: "proessentail",
                value: "Standing Kiosk",
                label: "Standing Kiosk",
                onClickImg: 'Standing Kiosk'
            })
        };
        if(isCounterKioskIncluded) {
            planKioskList.push({
                id: isCounterKioskIncluded.id,
                name: "proessentail",
                value: "Counter Kiosk",
                label: "Counter Kiosk",
                onClickImg: 'Counter Kiosk'
            })
        };
        if(isClampKioskIncluded) {
            planKioskList.push({
                id: isClampKioskIncluded.id,
                name: "proessentail",
                value: "Clamp Kiosk",
                label: "Clamp Kiosk",
                onClickImg: 'Clamp Kiosk'
            })
        };

        return { ...acc, [plan.id]: planKioskList}
    },{});
    return {...state, kioskListByPlan}
}

const getData = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        resetStore: () => { return {} },
        setQulificationStore: (state, action) => ({ ...state, ...action.payload }),
        setShippingAddressStore: (state, action) => ({ ...state, ...action.payload }),
        setValue: (state, action) => ({ ...state, ...action.payload }),
        setKisok: (state, action) => ({ ...state, ...action.payload }),
        setKioskByPlan: setKioskListByPlan,
    }
})
export const {
    resetStore,
    setQulificationStore,
    setShippingAddressStore,
    setValue,
    setKisok,
    setKioskByPlan,
} = getData.actions
export default getData.reducer;


import React, { Fragment, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loader from '../component/common/Loader'

const StepZero = lazy(() => import('../component/layout/sign-up/StepZero'))
const StepOne = lazy(() => import('../component/layout/sign-up/StepZero'))
const CartLandingPage = lazy(() => import('../component/layout/your-cart/CartLandingPage'))
const BussinessInfoLandingPage = lazy(() => import('../component/layout/business-information/BussinessInfoLandingPage'))
const PlanLanding = lazy(() => import('../component/layout/plan/PlanLanding'))
const SuitesLandingPage = lazy(() => import('../component/layout/plan/complate-suites-plan/SuitesLandingPage'))
const DigitalLandingPage = lazy(() => import('../component/layout/plan/digital-plan/DigitalLandingPage'))
const TandCLandingPage = lazy(() => import('../component/layout/t&c-pages/TandCLandingPage'))
const WishlistLanding = lazy(() => import('../component/layout/wish-list/WishlistLanding'))
const WishlistEnding = lazy(() => import('../component/layout/wish-list/WishlistEnding'))
const AccountPlaidLanding = lazy(() => import('../component/layout/account-plaid/AccountPlaidLanding'))
const AccountPlaidVerification = lazy(() => import('../component/layout/account-plaid/AccountPlaidVerification'))
const ReviewOrderLanding = lazy(() => import('../component/layout/review-order/ReviewOrderLanding'))
const WelcomeReviLanding = lazy(() => import('../component/layout/welcome-revi/WelcomeReviLanding'))
const DocumentSignLandingPage = lazy(() => import('../component/layout/t&c-pages/DocumentSignLandingPage'))

function Routing() {

  const dataArray = [
    {
      path: '/',
      element: <StepOne />
    },
    {
      path: '/steps',
      element: <StepZero />
    },
    {
      path: '/steps/wishlist',
      element: <WishlistLanding />
    },
    {
      path: '/wishlist-end',
      element: <WishlistEnding />
    },

    /* Plans Route */
    {
      path: '/plan/:signup_id',
      element: <PlanLanding />
    },
    {
      // Essential Plan
      path: '/pro-essential-plan/:signup_id',
      element: <SuitesLandingPage />
    },
    {
      path: '/digital-plan/:signup_id',
      element: <DigitalLandingPage />
    },

    /* Your-Cart Route */
    {
      path: '/cart-landing/:signup_id',
      element: <CartLandingPage />
    },

    /* Business Information Route */
    {
      path: '/business-information/:signup_id',
      element: <BussinessInfoLandingPage />
    },

    /* Account Plaid Route */
    {
      path: `/account-plaid/:signup_id`,
      element: <AccountPlaidLanding />
    },
    {
      path: `/account-plaid-verification/:signup_id`,
      element: <AccountPlaidVerification />
    },

    /* Term & Conditions Route */
    {
      path: '/doc-sign/:signup_id',
      element: <TandCLandingPage />
    },
    {
      path: `/doc-sign/contract/:signup_id`,
      element: <DocumentSignLandingPage />
    },

    /* Review Order Route */
    {
      path: `/review-order/:signup_id`,
      element: <ReviewOrderLanding />
    },

    /* Welcome Page Route */
    {
      path: '/welcome-revi',
      element: <WelcomeReviLanding />
    }
  ]
  return (
    <>
      <Routes>
        {dataArray?.map((item, index) => {
          return (
            <Fragment key={index}>
              <Route exact path={item?.path} element={
                <Suspense
                  fallback={<Loader />}>
                  {item?.element}
                </Suspense>
              } />
            </Fragment>
          )
        })}
      </Routes>
    </>
  )
}

export default Routing

import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    orange: '#FF4F21',
    blue: '#000648',
    blueLightOne: '#B7B8C5',
    blueLightTwo: '#112465',
    blueLightThree: '#315ADF',
    blueLightFour: '#2020DF',
    white: '#FFFFFF',
    black: '#000000',
    greyLightOne: '#F5F5F5',
    greyLightTwo: '#5F5F5F',
    greyLightThree: '#878787',
  },
});